import React from 'react';

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="container-fluid Container-Disclaimer pt-5">
                <div className="row justify-content-center">
                    <div className="d-none d-lg-flex col-8">
                    </div>
                    <div className="col-12 col-lg-12">
                        <div className="fbnr19 pb-5">Drink responsibly. Corona Premier® Beer. Imported by Crown Imports,
                            Chicago, IL Per 12 fl. oz. serving average analysis: Cals 90, Carbs 2.6, Protein 0.7 g, Fat
                            0.0g.
                        </div>

                        <div className="fbnr19 pb-3">Corona Premier© Golf Trip Sweepstakes 2022</div>
                        <div className="fbnr19 pb-5">NO PURCHASE NECESSARY. Open only to legal U.S. residents residing in New York, Vermont, 21 or older.  Starts 12:00 AM (ET) on March 14, 2022 and ends 11:59 PM (ET) on May 08, 2022.  The Corona Premier Golf Trip Sweepstakes is sponsored by Crown Imports LLC.  No alcohol awarded with prizes.  Void outside New York, Vermont and where prohibited.  For complete details, see Official Rules at www.premiergolftrip.com.
                        </div>
                        <div className="fbnr19 "><a href="http://www.premiergolftrip.com/pdf/rules.pdf" target="_blank"
                                                    role="button" className="linkbtn text-decoration-none">Official
                            Rules</a> |
                            <a href="https://www.cbrands.com/privacy-policy" target="_blank" role="button"
                               className="linkbtn text-decoration-none">Privacy Policy</a> |
                            <a href="https://www.cbrands.com/terms" target="_blank" role="button"
                               className="linkbtn text-decoration-none">Terms Of Use</a></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (Footer);
